<template>
  <v-expansion-panel>
    <v-expansion-panel-header class="headline">{{ $tc('label.apuracao_acao_detalhes_foco', 1)}}</v-expansion-panel-header>
    <v-expansion-panel-content>
      <card-info :campos="campos" :objeto="formatarObjeto()" />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import CardInfo from '../../shared-components/CardInfo';

export default {
  name: 'ApuracaoAcaoDetalhesFoco',
  props: {
    acao: Object,
    campoCliente: {
      type: String,
      default: '',
    },
  },
  components: {
    CardInfo,
  },
  data() {
    return {
    };
  },
  computed: {
    campos() {
      return [
        { campo: 'cliente', label: this.$tc('label.cliente', 1), hint: 'cnpj' },
        { campo: 'holding', label: this.$tc('label.holding', 1) },
        { campo: 'regional', label: this.$tc('label.regional', 1) },
        { campo: 'campoCliente', label: this.$tc(`label.${this.campoCliente}`, 1) },
        { campo: 'clientePagador', label: this.$tc('label.cliente_pagador', 1), hint: 'cnpjPagador' },
        { campo: 'fornecedorPagador', label: this.$tc('label.fornecedor_pagador', 1), hint: 'cnpjPagador' },
        { campo: 'tipoPagamento', label: this.$tc('label.tipo_pagamento', 1), i18n: true },
        { campo: 'tiposVerbas', label: this.$tc('label.tipo_de_verba', 1) },
      ];
    },
  },
  methods: {
    formatarObjeto() {
      const acaoFormatada = { ...this.acao };
      if (this.acao && this.acao.tipoPagamento) {
        acaoFormatada.tipoPagamento = this.$tc(`tipos_pagamentos.${this.acao.tipoPagamento.toUpperCase()}`, 1);
      }
      acaoFormatada.cnpjPagador = `CNPJ: ${acaoFormatada.cnpjPagador}`;
      return acaoFormatada;
    },
  },
};
</script>
